body {
    height: 100%;
    margin: 0px;
}

#container {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

header {
    padding: 12px 0;
    background-color: var(--neutral-layer-4);
    grid-area: header;
    display: grid;
    grid-template-columns: 280px 1fr auto auto;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: flex-start;
    border-bottom: calc(var(--stroke-width) * 3px) solid var(--accent-fill-rest)
}

header .logo {
    width: 108px;
    height: 46px;
    padding: 0 30px;
    grid-column: 1;
}

header .colorgroup {
    grid-column: 3;
    display: none;
    align-items: center;
}

header .profile {
    color: var(--neutral-foreground-rest);
    grid-column: 4;
    justify-self: end;
    padding: 0 20px 0 0;
    display: flex;
    align-items: flex-end;
}

footer {
    display: grid;
    grid-template-columns: 10px auto auto;
    background: var(--neutral-layer-4);
    color: var(--neutral-foreground-rest);
    align-items: center;
    grid-area: footer;
    padding: 10px 10px;
}

footer fluent-switch {
    width: 9rem;
}

footer .switches {
    grid-column: 2;
    display: flex;
    align-items: flex-start;
}

footer .version {
    grid-column: 3;
    justify-content: center;
}

footer .version a {
    color: var(--neutral-foreground-rest);
    text-decoration: none;
}

footer .version a:focus {
    outline: 1px dashed;
    outline-offset: 3px;
}

footer .version a:hover {
    text-decoration: underline;
}

footer .copy {
    grid-column: 5;
    justify-self: end;
}

main {
    grid-area: main;
    background-color: var(--neutral-layer-1);
    color: var(--neutral-foreground-rest);
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-template-rows: 1fr;
}

nav {
    z-index: 1;
    padding: 2.5rem 1.5rem;
    background-color: var(--neutral-layer-3);
    transition: all 300ms ease-in-out;
    grid-column: 1 / 2;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    padding: 2px 0;
}

nav h2 {
    font-size: var(--type-ramp-plus-1-font-size);
    line-height: var(--type-ramp-plus-1-line-height);
    padding: 15px 0;
    margin: 0;
}

nav h3 {
    font-size: var(--type-ramp-base-font-size);
    line-height: var(--type-ramp-minus-1-line-height);
    padding: 10px 0;
    margin: 0;
}


nav button {
    width: 100%;
    color: var(--fill-color);
    text-align: left !important;
}

/* nav button::part(control) {
    justify-content: start;
    background: var(--accent-fill-rest);
} */

.content {
    display: grid;
    grid-template-columns: 3fr 280px;
}

article {
    grid-area: 1 / 1;
    padding: 2.2rem 1.5rem;
    margin: 0 0;
    overflow-x: hidden;
    transition: all 300ms ease-in-out;
}

aside {
    padding: 2rem 0.75rem;
    border-left: 1px solid var(--neutral-stroke-divider-rest);
    grid-area: 1 / 2;
}

#menu-toggle {
    display: none;
}

.menu-icon {
    display: none;
}

#menu-toggle:checked>nav {
    width: 0px;
}

[dir="rtl"] #menu-toggle:checked~nav {
    right: 0px;
}

#color {
    margin-right: 10px;
    margin-left: 0;
}

[dir="rtl"] #color {
    margin-left: 10px;
    margin-right: 0;
}


label {
    color: var(--neutral-foreground-rest);
    font-family: var(--body-font);
    font-size: var(--type-ramp-base-font-size);
    line-height: var(--type-ramp-base-line-height);
    font-weight: initial;
    font-variation-settings: var(--type-ramp-base-font-variations);
    margin-inline-end: calc(var(--design-unit) * 2px + 2px);
    cursor: pointer;
}

.shell,
.sourceCode {
    background: var(--neutral-stroke-layer-rest);
    padding: 7px;
}

code {
    background: var(--neutral-stroke-layer-rest);
}

.demopanel {
    border: 1px dashed red;
    padding: 5px;
}

.highlighted-row {
    background-color: var(--neutral-fill-secondary-hover);
}

.treeStatus {
    display: flex;
    align-items: center;
}

.treeStatus label {
    margin-left: .5em;
}

.mindMapContainer {
    position: relative;
    height: 0;
    padding-top: 83.75%;
    width:100%;
  }
  .mindMapContainer iframe {
    position: absolute;
    top: 0; left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }

@media (max-width: 767px) {

    header {
        grid-area: header;
        grid-template-columns: 150px 1fr;
        align-items: center;
        justify-content: flex-start;
    }

    header .logo {
        width: 160px;
        height: 46px;
        padding: 0 25px;
    }


    header .switches {
        flex-direction: column;
        grid-column: 2
    }

    header .switches .label {
        padding-inline-end: 14px;
    }

    header .colorgroup {
        display: none;
    }

    nav {
        padding: 0%;
    }
    .navMenuFull {
        display: none;
    }

    main {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 40px 1fr;
    }

    aside {
        grid-area: 1 / 1 / 2 / 3;
        padding: 1em 0.75em;
    }

    article {
        grid-area: 2 / 1 / 3 / 3;
        padding-top: 0px;
    }

    #menu-toggle:checked~article {
        padding-left: 0;
        grid-area: 1 / 1 / 3 / 3;
    }

    nav ul {
        text-align: center;
        max-width: 100%;
    }

    .menu-icon {
        padding: 10px 0.75em;
        z-index: 2;
        transition: all 300ms ease-in-out;
        display: block;
        visibility: visible;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        width: 4rem;
    }

    #menu-toggle~nav {
        display: none;
    }

    #menu-toggle:checked~nav {
        display: block;
        grid-area: 1 / 1 / 3 / 3;
    }

    #menu-toggle~article {
        display: block;
    }

    #menu-toggle:checked~article {
        display: none;
    }

    #menu-toggle:checked+.menu-icon {
        padding-left: 89%;
    }

    [dir="rtl"] #menu-toggle:checked+.menu-icon {}

    footer {
        display: grid;
        grid-template-columns: 10px auto 10px;
    }

    footer .version {
        grid-column: 2;
        justify-content: start;
    }

    footer .copy {
        grid-column: 2;
        grid-row: 2;
        justify-self: end;
    }


    @media screen and (max-width: 767px) and (orientation: landscape) {

        nav {
            padding: 25px 40px;
            height: 300px;
        }

        nav ul {
            margin: 0 0;
        }

        nav ul li {}
    }
}

@media (max-width: 1024px) {
    fluent-select::part(control) {
        width: 150px;
    }

    .content {
        display: grid;
        grid-template-columns: auto;
    }

    aside {
        grid-area: 1 / 1 / 2 / 2;
        padding: 1.5em 0.75em 1em 0.75em;
    }

    article {
        grid-area: 2 / 1 / 2 / 2;
        padding-top: 0px;
    }
}


/* Surface Duo specific styling */
@media (horizontal-viewport-segments: 2) {

    header {
        grid-area: header;
        display: grid;
        grid-template-columns: 150px calc(env(viewport-segment-width 0 0) - 160px) 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 0;
        background-color: var(--neutral-layer-4);
    }

    header a {
        padding: 0px 15px;
        color: var(--neutral-foreground-rest);
    }

    header .logo {
        grid-column: 1;
        width: 108px;
        height: 23px;
        padding: 0 30px;
    }

    header .switches {
        margin-inline-start: calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0));
        /* hinge width */
        grid-column: 3;
        justify-self: end;
        padding-left: 20px;
    }

    header .colorgroup {
        grid-column: 2;
        justify-self: end;
        z-index: 10;
    }

    main {
        display: grid;
        grid-template-columns: env(viewport-segment-width 0 0) 1fr;
        grid-template-rows: repeat(0, 1fr);
    }

    nav {
        grid-column: 1;
        width: env(viewport-segment-width 0 0)px;
    }

    .content {
        display: grid;
        grid-template-columns: auto;
    }

    aside {
        grid-area: 1 / 2 / 2 / 3;
        padding: 1.5em 0.75em 1em 0.75em;
        margin-inline-start: calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0));
        /* hinge width */
        margin-inline-end: calc(100% - env(viewport-segment-left 1 0));
    }

    article {
        grid-area: 2 / 2 / 3 / 3;
        padding-top: 0px;
        margin-inline-start: calc(env(viewport-segment-left 1 0) - env(viewport-segment-right 0 0));
        /* hinge width */
        margin-inline-end: calc(100% - env(viewport-segment-left 1 0));
    }
}


:root {
    --accent-color: #da1a5f;
    --accent-fill-active: #df3c77;
    --accent-fill-focus: #4e64bf;
    --accent-fill-hover: #da1a5f;
    --accent-fill-rest: #4e64bf;
    --accent-foreground-active: #4e64bf;
    --accent-foreground-focus: #da1a5f;
    --accent-foreground-hover: #b3154e;
    --accent-foreground-rest: #da1a5f;
    --ambient-shadow-alpha: calc(0.11 * (2 - var(--background-luminance, 1)));
    --ambient-shadow-color: rgba(0, 0, 0, var(--ambient-shadow-alpha));
    --ambient-shadow: 0 0 calc((var(--elevation) * 0.225px) + 2px) var(--ambient-shadow-color);
    --base-height-multiplier: 10;
    --base-horizontal-spacing-multiplier: 3;
    --base-layer-luminance: 1;
    --body-font: Arial, Helvetica, sans-serif;
    --clear-button-active: #ededed;
    --clear-button-hover: #f2f2f2;
    --control-corner-radius: 4;
    --density: 0;
    --design-unit: 4;
    --direction: ltr;
    --directional-shadow: 0 calc(var(--elevation) * 0.4px) calc((var(--elevation) * 0.9px)) var(--directional-shadow-color);
    --directional-shadow-alpha: calc(0.13 * (2 - var(--background-luminance, 1)));
    --directional-shadow-color: rgba(0, 0, 0, var(--directional-shadow-alpha));
    --disabled-opacity: 0.3;
    --elevation: 14;
    --elevation-shadow: var(--ambient-shadow), var(--directional-shadow);
    --height-number: (var(--base-height-multiplier) + var(--density)) * var(--design-unit);
    --fill-color: #ffffff;
    --focus-stroke-inner: #ffffff;
    --focus-stroke-outer: #888888;
    --focus-stroke-width: 2;
    --foreground-on-accent-active-large: #000000;
    --foreground-on-accent-active: #000000;
    --foreground-on-accent-focus-large: #000000;
    --foreground-on-accent-focus: #ffffff;
    --foreground-on-accent-hover-large: #000000;
    --foreground-on-accent-hover: #ffffff;
    --foreground-on-accent-rest-large: #000000;
    --foreground-on-accent-rest: #ffffff;
    --neutral-color: #808080;
    --neutral-fill-active: #f2f2f2;
    --neutral-fill-focus: #ffffff;
    --neutral-fill-hover: #e5e5e5;
    --neutral-fill-input-active: #ffffff;
    --neutral-fill-input-focus: #ffffff;
    --neutral-fill-input-hover: #ffffff;
    --neutral-fill-input-rest: #ffffff;
    --neutral-fill-layer-rest: #f7f7f7;
    --neutral-fill-rest: #ededed;
    --neutral-fill-stealth-active: #f7f7f7;
    --neutral-fill-stealth-focus: #ffffff;
    --neutral-fill-stealth-hover: #f2f2f2;
    --neutral-fill-stealth-rest: #ffffff;
    --neutral-fill-strong-active: #838383;
    --neutral-fill-strong-focus: #767676;
    --neutral-fill-strong-hover: #616161;
    --neutral-fill-strong-rest: #767676;
    --neutral-foreground-hint: #767676;
    --neutral-foreground-rest: #2b2b2b;
    --neutral-layer-1: #ffffff;
    --neutral-layer-2: #e5e5e5;
    --neutral-layer-3: #dddddd;
    --neutral-layer-4: #d6d6d6;
    --neutral-layer-card-container: #f7f7f7;
    --neutral-layer-floating: #ffffff;
    --neutral-stroke-active: #d6d6d6;
    --neutral-stroke-divider-rest: #eaeaea;
    --neutral-stroke-focus: #bebebe;
    --neutral-stroke-hover: #979797;
    --neutral-stroke-rest: #bebebe;
    --stroke-width: 1;
    --tree-item-expand-collapse-hover: #e5e5e5;
    --tree-item-expand-collapse-selected-hover: #e0e0e0;
    --type-ramp-base-font-size: 14px;
    --type-ramp-base-line-height: 20px;
    --type-ramp-minus-1-font-size: 12px;
    --type-ramp-minus-1-line-height: 16px;
    --type-ramp-minus-2-font-size: 10px;
    --type-ramp-minus-2-line-height: 16px;
    --type-ramp-plus-1-font-size: 16px;
    --type-ramp-plus-1-line-height: 24px;
    --type-ramp-plus-2-font-size: 20px;
    --type-ramp-plus-2-line-height: 28px;
    --type-ramp-plus-3-font-size: 28px;
    --type-ramp-plus-3-line-height: 36px;
    --type-ramp-plus-4-font-size: 34px;
    --type-ramp-plus-4-line-height: 44px;
    --type-ramp-plus-5-font-size: 46px;
    --type-ramp-plus-5-line-height: 56px;
    --type-ramp-plus-6-font-size: 60px;
    --type-ramp-plus-6-line-height: 72px;
}

html,
.docs-story {
    background-color: var(--fill-color);
    color: var(--neutral-foreground-rest);
    min-height: 100%;
}

/* Disable zoom transform for Firefox, see https://github.com/storybookjs/storybook/issues/16774 */
.docs-story [class^="css-"] {
    transform: none !important;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}
